import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "entities" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_button = _resolveComponent("va-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
        _createVNode(_component_va_button, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(item.label) + ": " + _toDisplayString(item.confidence), 1)
          ]),
          _: 2
        }, 1024),
        _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
        _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1))
      ], 64))
    }), 128))
  ]))
}