import Base from '@/components/layouts/Base.vue';
import { NEWSLETTER_ARTICLE, NEWSLETTERS } from '@/config/routes';
import defaultRoute from '@/services/router/defaultRoute';

import Index from '../news/views/Index.vue';
import Article from './views/Article.vue';

export default [
  {
    path: '/360plus',
    component: Base,
    children: [
      {
        name: NEWSLETTERS,
        path: ':id?',
        component: Index,
        children: [
          {
            name: NEWSLETTER_ARTICLE,
            path: 'artikel/:resourceId/:articleId',
            component: Article,
          },
        ],
      },
    ],
  },
];
