import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "related"
}
const _hoisted_2 = { class: "display-6" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_icon = _resolveComponent("va-icon")!
  const _component_va_avatar = _resolveComponent("va-avatar")!
  const _component_va_list_item_section = _resolveComponent("va-list-item-section")!
  const _component_va_list_item_label = _resolveComponent("va-list-item-label")!
  const _component_va_list_item = _resolveComponent("va-list-item")!
  const _component_va_list = _resolveComponent("va-list")!
  const _component_va_chip = _resolveComponent("va-chip")!

  return (_ctx.renderItems?.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
          (_ctx.totalCount)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode("(" + _toDisplayString(_ctx.totalCount) + ")", 1)
              ], 64))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_va_list, null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.renderItems, (item, index) => {
              return (_openBlock(), _createBlock(_component_va_list_item, {
                key: item.id || index,
                to: {
          name: _ctx.routeName,
          params: {
            id: _ctx.workspaceId,
            resourceId: _ctx.resourceId,
            articleId: _ctx.getRelatedId(item),
          },
        }
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_va_list_item_section, { avatar: "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_va_avatar, { square: "" }, {
                        default: _withCtx(() => [
                          (item.thumbUrl)
                            ? (_openBlock(), _createElementBlock("img", {
                                key: 0,
                                class: "related__image",
                                src: item.thumbUrl,
                                alt: 
                item.kind === _ctx.RELATED_KINDS.PERSON && item.title
                  ? _ctx.getInitials(item.title)
                  : item.title
                  ? item.title
                  : ''
              ,
                                loading: "lazy"
                              }, null, 8, _hoisted_3))
                            : (item.kind === _ctx.RELATED_KINDS.PERSON && item.title)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.getInitials(item.title)), 1))
                              : (_openBlock(), _createBlock(_component_va_icon, {
                                  key: 2,
                                  name: "article"
                                }))
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_va_list_item_section, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_va_list_item_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.title), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_va_list_item_label, { caption: "" }, {
                        default: _withCtx(() => [
                          (item.pubDate && item.kind !== 'PERSON')
                            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.formatDate(new Date(item.pubDate))), 1))
                            : _createCommentVNode("", true),
                          (
                item.score &&
                (item.kind === 'PERSON' ||
                  _ctx.type == _ctx.ARTICLE_RELATIONSHIP.OTHERAGENCIES)
              )
                            ? (_openBlock(), _createElementBlock("span", _hoisted_6, " | " + _toDisplayString(item.score.toFixed(3)) + "  ", 1))
                            : _createCommentVNode("", true),
                          (item.sourceTitle)
                            ? (_openBlock(), _createElementBlock("span", {
                                key: 2,
                                class: _normalizeClass(item.kind !== 'PERSON' ? 'related__source' : '')
                              }, _toDisplayString(item.sourceTitle), 3))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["to"]))
            }), 128))
          ]),
          _: 1
        }),
        (_ctx.totalCount && _ctx.renderItems.length < _ctx.totalCount)
          ? (_openBlock(), _createBlock(_component_va_chip, {
              key: 0,
              class: "mt-2",
              size: "small",
              flat: "",
              onClick: _ctx.fetchNextPage
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('common.show-more')), 1)
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}