import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "image"
}
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = {
  key: 1,
  class: "audio",
  controls: ""
}
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 2,
  class: "video",
  controls: ""
}
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_button = _resolveComponent("va-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.audio && !_ctx.video && !_ctx.localfocuswidget && _ctx.image)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("img", {
            src: _ctx.getMediaUrl(_ctx.image.id),
            alt: _ctx.title,
            class: "image-image"
          }, null, 8, _hoisted_2),
          (_ctx.image.origin)
            ? (_openBlock(), _createBlock(_component_va_button, {
                key: 0,
                href: _ctx.image.origin,
                target: "_blank",
                rel: "nofollow noreferrer noopener",
                class: "image-button",
                icon: "shopping_cart",
                color: "white",
                flat: ""
              }, null, 8, ["href"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.audio)
      ? (_openBlock(), _createElementBlock("audio", _hoisted_3, [
          _createElementVNode("source", {
            src: _ctx.getMediaUrl(_ctx.audio.id),
            type: "audio/mpeg"
          }, null, 8, _hoisted_4)
        ]))
      : _createCommentVNode("", true),
    (_ctx.video)
      ? (_openBlock(), _createElementBlock("video", _hoisted_5, [
          _createElementVNode("source", {
            src: _ctx.getMediaUrl(_ctx.video.id),
            type: "video/mp4"
          }, null, 8, _hoisted_6)
        ]))
      : _createCommentVNode("", true),
    (_ctx.localfocuswidget)
      ? (_openBlock(), _createElementBlock("iframe", {
          key: 3,
          class: "localfocusvisual",
          frameborder: "0",
          style: {"width":"100%","height":"440px","overflow":"hidden"},
          src: _ctx.localfocuswidget.origin
        }, null, 8, _hoisted_7))
      : _createCommentVNode("", true)
  ], 64))
}