import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, renderSlot as _renderSlot, createVNode as _createVNode, withKeys as _withKeys, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "search-form row gutter--sm" }
const _hoisted_4 = { class: "flex flex--grow" }
const _hoisted_5 = { class: "mb-6" }
const _hoisted_6 = { class: "mb-6" }
const _hoisted_7 = {
  key: 1,
  class: "mb-6"
}
const _hoisted_8 = { class: "row justify--space-between" }
const _hoisted_9 = {
  key: 0,
  class: "mb-6"
}
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = {
  key: 0,
  class: "search-form__sidebar"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_progress_circle = _resolveComponent("va-progress-circle")!
  const _component_va_alert = _resolveComponent("va-alert")!
  const _component_anp_message = _resolveComponent("anp-message")!
  const _component_anp_label = _resolveComponent("anp-label")!
  const _component_va_radio = _resolveComponent("va-radio")!
  const _component_va_input = _resolveComponent("va-input")!
  const _component_va_switch = _resolveComponent("va-switch")!
  const _component_va_collapse = _resolveComponent("va-collapse")!
  const _component_va_accordion = _resolveComponent("va-accordion")!
  const _component_va_select = _resolveComponent("va-select")!
  const _component_va_date_input = _resolveComponent("va-date-input")!
  const _component_SourcesWeaviate = _resolveComponent("SourcesWeaviate")!
  const _component_va_button = _resolveComponent("va-button")!
  const _component_SaveQuery = _resolveComponent("SaveQuery")!
  const _component_anp_form = _resolveComponent("anp-form")!
  const _component_Results = _resolveComponent("Results")!
  const _component_MediaTopics = _resolveComponent("MediaTopics")!
  const _component_Facet = _resolveComponent("Facet")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.promptloading)
      ? (_openBlock(), _createBlock(_component_va_progress_circle, {
          key: 0,
          class: "loader",
          size: "small",
          thickness: 0.2,
          indeterminate: ""
        }))
      : _createCommentVNode("", true),
    (_ctx.promptAnswer)
      ? (_openBlock(), _createBlock(_component_va_alert, {
          key: 1,
          class: "message",
          border: "left"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.promptAnswer) + " ", 1),
            (_ctx.promptReferedArticles?.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _cache[20] || (_cache[20] = _createElementVNode("br", { style: {"margin":"10px"} }, null, -1)),
                  _createElementVNode("b", null, _toDisplayString(_ctx.$t('common.refered-articles')) + ":", 1),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.promptReferedArticles, (article) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "promptReferedArticle",
                      key: article.id,
                      onClick: ($event: any) => (_ctx.handleClick(article.id))
                    }, [
                      _createTextVNode(_toDisplayString(article.pubdate) + " " + _toDisplayString(article.source) + " " + _toDisplayString(article.title), 1),
                      _cache[19] || (_cache[19] = _createElementVNode("br", null, null, -1))
                    ], 8, _hoisted_2))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: _normalizeClass({ inset: _ctx.modalForm })
        }, [
          _createVNode(_component_anp_form, {
            ref: "form",
            onSubmit: _ctx.handleSubmit
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "header", {}, undefined, true),
              _createVNode(_component_anp_message, { message: _ctx.message }, null, 8, ["message"]),
              _createVNode(_component_anp_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.sort-by')), 1)
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_va_radio, {
                  modelValue: _ctx.sortBy,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sortBy) = $event)),
                  option: _ctx.SORT_TYPES.DATE,
                  label: _ctx.$t('common.date')
                }, null, 8, ["modelValue", "option", "label"]),
                (!_ctx.query)
                  ? (_openBlock(), _createBlock(_component_va_radio, {
                      key: 0,
                      modelValue: _ctx.sortBy,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sortBy) = $event)),
                      option: _ctx.SORT_TYPES.RELEVANCE,
                      label: _ctx.$t('common.relevance')
                    }, null, 8, ["modelValue", "option", "label"]))
                  : _createCommentVNode("", true)
              ]),
              _createVNode(_component_anp_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('search.search-concept')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_va_input, {
                class: "mb-8",
                modelValue: _ctx.queryConcept,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.queryConcept) = $event)),
                type: "textarea",
                rows: "2 ",
                onKeydown: _withKeys(_ctx.handleKey, ["enter"])
              }, null, 8, ["modelValue", "onKeydown"]),
              _createVNode(_component_anp_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('search.boolean-filter')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_va_input, {
                class: "mb-8",
                modelValue: _ctx.queryText,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.queryText) = $event)),
                type: "textarea",
                disabled: _ctx.searchengine != 'elastic',
                rows: "2",
                onKeydown: _withKeys(_ctx.handleKey, ["enter"])
              }, null, 8, ["modelValue", "disabled", "onKeydown"]),
              _createVNode(_component_va_accordion, {
                modelValue: _ctx.accordionrefinements,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.accordionrefinements) = $event)),
                class: "mb-8",
                multiply: true
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_va_collapse, {
                    header: _ctx.$t('search.extensive-search')
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", null, [
                        _createVNode(_component_anp_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('search.reduce-language-bias')), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_va_switch, {
                          modelValue: _ctx.reduceLanguageBias,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.reduceLanguageBias) = $event)),
                          class: "mb-2",
                          size: "small"
                        }, null, 8, ["modelValue"]),
                        _createVNode(_component_anp_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('common.minscore')), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_va_input, {
                          modelValue: _ctx.minScore,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.minScore) = $event))
                        }, null, 8, ["modelValue"]),
                        _createVNode(_component_anp_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('search.focus-search-concepts')), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_va_input, {
                          class: "mb-8",
                          modelValue: _ctx.plusTerms,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.plusTerms) = $event)),
                          type: "textarea",
                          rows: "2",
                          onKeydown: _withKeys(_ctx.handleKey, ["enter"])
                        }, null, 8, ["modelValue", "onKeydown"]),
                        _createVNode(_component_anp_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('search.not-search-concepts')), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_va_input, {
                          class: "mb-8",
                          modelValue: _ctx.notTerms,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.notTerms) = $event)),
                          type: "textarea",
                          rows: "2",
                          onKeydown: _withKeys(_ctx.handleKey, ["enter"])
                        }, null, 8, ["modelValue", "onKeydown"]),
                        _createVNode(_component_anp_label, null, {
                          default: _withCtx(() => _cache[21] || (_cache[21] = [
                            _createTextVNode("Vectorizer")
                          ])),
                          _: 1
                        }),
                        _createElementVNode("div", _hoisted_6, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.features?.embeddingModels, (item) => {
                            return (_openBlock(), _createBlock(_component_va_radio, {
                              key: item,
                              modelValue: _ctx.vectorizer,
                              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.vectorizer) = $event)),
                              option: item,
                              label: item
                            }, null, 8, ["modelValue", "option", "label"]))
                          }), 128))
                        ]),
                        (!_ctx.query && _ctx.features?.weaviate)
                          ? (_openBlock(), _createBlock(_component_anp_label, { key: 0 }, {
                              default: _withCtx(() => _cache[22] || (_cache[22] = [
                                _createTextVNode("Search engine")
                              ])),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (!_ctx.query && _ctx.features?.weaviate)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                              (_ctx.features?.weaviate)
                                ? (_openBlock(), _createBlock(_component_va_radio, {
                                    key: 0,
                                    modelValue: _ctx.searchengine,
                                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.searchengine) = $event)),
                                    option: "weaviate",
                                    label: "Weaviate"
                                  }, null, 8, ["modelValue"]))
                                : _createCommentVNode("", true),
                              _createVNode(_component_va_radio, {
                                modelValue: _ctx.searchengine,
                                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.searchengine) = $event)),
                                option: "elastic",
                                label: "Elastic"
                              }, null, 8, ["modelValue"])
                            ]))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 1
                  }, 8, ["header"])
                ]),
                _: 1
              }, 8, ["modelValue"]),
              _createVNode(_component_va_accordion, {
                modelValue: _ctx.accordion,
                "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.accordion) = $event)),
                class: "mb-8",
                multiply: true
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_va_collapse, {
                    header: _ctx.$t('search.refinement-search')
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", null, [
                        _createVNode(_component_anp_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('common.language')), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_va_select, {
                          modelValue: _ctx.language,
                          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.language) = $event)),
                          options: _ctx.languageOptions,
                          multiple: ""
                        }, null, 8, ["modelValue", "options"]),
                        (!_ctx.query)
                          ? (_openBlock(), _createBlock(_component_anp_label, { key: 0 }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('common.period')), 1)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (!_ctx.query)
                          ? (_openBlock(), _createBlock(_component_va_select, {
                              key: 1,
                              modelValue: _ctx.period,
                              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.period) = $event)),
                              options: _ctx.periodOptions
                            }, null, 8, ["modelValue", "options"]))
                          : _createCommentVNode("", true),
                        (_ctx.period.value === '-1')
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                              _createVNode(_component_anp_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('search.period-from-to')), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_va_date_input, {
                                modelValue: _ctx.range,
                                "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.range) = $event)),
                                "format-date": (date) => _ctx.formatDate(date),
                                mode: "range",
                                "range-delimiter": " / "
                              }, null, 8, ["modelValue", "format-date"])
                            ], 64))
                          : _createCommentVNode("", true),
                        (!_ctx.query)
                          ? (_openBlock(), _createBlock(_component_anp_label, { key: 3 }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('common.priority')), 1)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (!_ctx.query)
                          ? (_openBlock(), _createBlock(_component_va_select, {
                              key: 4,
                              modelValue: _ctx.priority,
                              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.priority) = $event)),
                              options: _ctx.prioOptions
                            }, null, 8, ["modelValue", "options"]))
                          : _createCommentVNode("", true),
                        _createVNode(_component_anp_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('common.minwords')), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_va_input, {
                          modelValue: _ctx.minWords,
                          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.minWords) = $event))
                        }, null, 8, ["modelValue"])
                      ])
                    ]),
                    _: 1
                  }, 8, ["header"])
                ]),
                _: 1
              }, 8, ["modelValue"]),
              _createVNode(_component_anp_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.sources')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_va_accordion, {
                modelValue: _ctx.accordionsources,
                "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.accordionsources) = $event)),
                class: "mb-8",
                multiply: true
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_va_collapse, {
                    header: _ctx.$t('common.sources')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_SourcesWeaviate, {
                        ref: "sourcesEl",
                        init: _ctx.sources
                      }, null, 8, ["init"])
                    ]),
                    _: 1
                  }, 8, ["header"])
                ]),
                _: 1
              }, 8, ["modelValue"]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_va_button, {
                  type: "submit",
                  block: "",
                  loading: _ctx.loading
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('common.search')), 1)
                  ]),
                  _: 1
                }, 8, ["loading"]),
                (
                ((_ctx.features?.managePrivateWorkspaces &&
                  _ctx.features?.managePrivateQueries) ||
                  (_ctx.features?.manageSharedWorkspaces &&
                    _ctx.features?.manageSharedQueries)) &&
                _ctx.items.length
              )
                  ? (_openBlock(), _createBlock(_component_va_button, {
                      key: 0,
                      class: "mt-2",
                      block: "",
                      onClick: _ctx.showSave
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.query
                  ? _ctx.$t('search.overwrite-search-query')
                  : _ctx.$t('search.save-search-query')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_SaveQuery, {
                  ref: "saveModal",
                  onDone: _ctx.handleDone,
                  query: _ctx.query,
                  type: _ctx.type,
                  searchData: {
                queryConcept: _ctx.queryConcept,
                queryText: _ctx.queryText,
                queryTitle: _ctx.queryTitle,
                queryAuthor: _ctx.queryAuthor,
                period: _ctx.period,
                fromDate: _ctx.fromDate,
                toDate: _ctx.toDate,
                sourceId: _ctx.sourceId,
                sourcesIds: _ctx.sourcesIds,
                calendarsIds: _ctx.calendarsIds,
                minScore: _ctx.minScore,
                minWords: _ctx.minWords,
                vectorizer: _ctx.vectorizer,
                notTerms: _ctx.notTerms,
                plusTerms: _ctx.plusTerms,
                reduceLanguageBias: _ctx.reduceLanguageBias,
              }
                }, null, 8, ["onDone", "query", "type", "searchData"])
              ]),
              (_ctx.query?.feedback)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _cache[23] || (_cache[23] = _createElementVNode("div", null, [
                      _createElementVNode("b", null, "Feedback"),
                      _createElementVNode("br"),
                      _createElementVNode("br")
                    ], -1)),
                    _createElementVNode("div", null, "Aantal: " + _toDisplayString(_ctx.query.feedback.numFeedBack), 1),
                    _createElementVNode("div", null, "Aantal positief: " + _toDisplayString(_ctx.query.feedback.numFeedBackPos), 1),
                    _createElementVNode("div", null, "Aantal negatief: " + _toDisplayString(_ctx.query.feedback.numFeedBackNeg), 1),
                    _createElementVNode("div", null, " Gemiddeld positief (min3): " + _toDisplayString(_ctx.query.feedback.avgFeedBackPos3), 1),
                    _createElementVNode("div", null, " Gemiddeld negatief (max3): " + _toDisplayString(_ctx.query.feedback.avgFeedBackNeg3), 1),
                    _createElementVNode("div", null, "Gemiddeld positief: " + _toDisplayString(_ctx.query.feedback.avgFeedBackPos), 1),
                    _createElementVNode("div", null, "Gemiddeld negatief: " + _toDisplayString(_ctx.query.feedback.avgFeedBackNeg), 1),
                    _createElementVNode("div", null, "Max positief: " + _toDisplayString(_ctx.query.feedback.maxFeedBackPos), 1),
                    _createElementVNode("div", null, "Max negatief: " + _toDisplayString(_ctx.query.feedback.maxFeedBackNeg), 1),
                    _createElementVNode("div", null, "Min positief: " + _toDisplayString(_ctx.query.feedback.minFeedBackPos), 1),
                    _createElementVNode("div", null, "Min negatief: " + _toDisplayString(_ctx.query.feedback.minFeedBackNeg), 1),
                    _cache[24] || (_cache[24] = _createElementVNode("br", null, null, -1)),
                    (_ctx.query.feedback.ok)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, "status: ok"))
                      : _createCommentVNode("", true),
                    (!_ctx.query.feedback.ok)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_11, "status: not ok"))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", null, "reason: " + _toDisplayString(_ctx.query.feedback.reason), 1)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 3
          }, 8, ["onSubmit"])
        ], 2)
      ]),
      _createVNode(_component_Results, {
        items: _ctx.items,
        count: _ctx.count,
        showDescription: _ctx.showDescription,
        square: _ctx.modalForm,
        "loading-more": _ctx.loadingMore || _ctx.loading,
        onLoadmore: _ctx.loadMore
      }, null, 8, ["items", "count", "showDescription", "square", "loading-more", "onLoadmore"]),
      (_ctx.facets.size > 0 || _ctx.mediatopics.length > 0)
        ? (_openBlock(), _createElementBlock("aside", _hoisted_12, [
            (_ctx.mediatopics.length > 0)
              ? (_openBlock(), _createBlock(_component_MediaTopics, {
                  key: 0,
                  kind: "Mediatopics",
                  items: _ctx.mediatopics
                }, null, 8, ["items"]))
              : _createCommentVNode("", true),
            (_ctx.facets.get('source'))
              ? (_openBlock(), _createBlock(_component_Facet, {
                  key: 1,
                  kind: "Bron",
                  items: _ctx.facets.get('source')
                }, null, 8, ["items"]))
              : _createCommentVNode("", true),
            (_ctx.facets.get('PERSON'))
              ? (_openBlock(), _createBlock(_component_Facet, {
                  key: 2,
                  kind: "Personen",
                  items: _ctx.facets.get('PERSON')
                }, null, 8, ["items"]))
              : _createCommentVNode("", true),
            (_ctx.facets.get('LOCATION'))
              ? (_openBlock(), _createBlock(_component_Facet, {
                  key: 3,
                  kind: "Locatie",
                  items: _ctx.facets.get('LOCATION')
                }, null, 8, ["items"]))
              : _createCommentVNode("", true),
            (_ctx.facets.get('ORGANISATION'))
              ? (_openBlock(), _createBlock(_component_Facet, {
                  key: 4,
                  kind: "Organisaties",
                  items: _ctx.facets.get('ORGANISATION')
                }, null, 8, ["items"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}