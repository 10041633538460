import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "news p-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_Draggable = _resolveComponent("Draggable")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.workspace)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_Draggable, {
            modelValue: _ctx.workspace.items,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.workspace.items) = $event)),
            group: "workspaces",
            handle: ".handle",
            "item-key": "resourceId",
            class: _normalizeClass(["view row gutter--xs", { snap: !_ctx.dragging }]),
            onStart: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dragging = true)),
            onEnd: _cache[2] || (_cache[2] = ($event: any) => (_ctx.dragging = false)),
            onChange: _ctx.handleChangeResource
          }, {
            item: _withCtx(({ element: resource }) => [
              (resource.settings?.isVisible ?? true)
                ? (_openBlock(), _createBlock(_component_Column, {
                    key: 0,
                    resource: resource,
                    workspace: _ctx.workspace,
                    draggable: ""
                  }, null, 8, ["resource", "workspace"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["modelValue", "class", "onChange"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view)
  ], 64))
}