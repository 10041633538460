import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-4" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "mt-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_content = _resolveComponent("va-content")!

  return (_openBlock(), _createBlock(_component_va_content, null, {
    default: _withCtx(() => [
      _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.policy.common.title), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.policy.sections, (section) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: section }, [
          _createElementVNode("h2", null, _toDisplayString(section.title), 1),
          _createElementVNode("div", {
            innerHTML: section.body
          }, null, 8, _hoisted_2)
        ], 64))
      }), 128)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", null, _toDisplayString(_ctx.policy.common.publishDate), 1)
      ])
    ]),
    _: 1
  }))
}