import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "article__title mb-4" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = {
  key: 0,
  class: "mb-4"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 3,
  class: "article__meta mb-6"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = {
  key: 2,
  class: "notranslate"
}
const _hoisted_10 = { key: 3 }
const _hoisted_11 = { key: 4 }
const _hoisted_12 = { key: 5 }
const _hoisted_13 = { key: 6 }
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = {
  key: 6,
  style: {"flex":"1"}
}
const _hoisted_16 = ["src"]
const _hoisted_17 = { key: 7 }
const _hoisted_18 = { key: 8 }
const _hoisted_19 = { key: 9 }
const _hoisted_20 = { key: 10 }
const _hoisted_21 = { class: "article__meta" }
const _hoisted_22 = {
  key: 1,
  class: "notranslate"
}
const _hoisted_23 = {
  key: 2,
  class: "notranslate"
}
const _hoisted_24 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Media = _resolveComponent("Media")!
  const _component_va_button = _resolveComponent("va-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h1", {
        innerHTML: _ctx.article.title
      }, null, 8, _hoisted_2),
      _createElementVNode("img", {
        class: "article__title-image",
        src: `${_ctx.baseUri}sources/${_ctx.article.source}/logo`,
        alt: _ctx.$t('common.origin'),
        loading: "lazy"
      }, null, 8, _hoisted_3)
    ]),
    (_ctx.article.origTitle)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("h2", null, "(" + _toDisplayString(_ctx.article.origTitle) + ")", 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.article.editorialNote)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          innerHTML: _ctx.article.editorialNote,
          class: "article__editor-note my-4"
        }, null, 8, _hoisted_5))
      : _createCommentVNode("", true),
    (_ctx.article.kind === _ctx.ARTICLE_TYPES.EVENT)
      ? (_openBlock(), _createBlock(_component_Calendar, {
          key: 2,
          article: _ctx.article,
          docked: _ctx.docked
        }, null, 8, ["article", "docked"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
          (_ctx.formattedDate)
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.formattedDate), 1))
            : _createCommentVNode("", true),
          (_ctx.formattedTime)
            ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.formattedTime), 1))
            : _createCommentVNode("", true),
          (_ctx.article.sourceTitle)
            ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.article.sourceTitle), 1))
            : _createCommentVNode("", true),
          (_ctx.article.urgency)
            ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.$t('common.priority')) + ": " + _toDisplayString(_ctx.article.urgency), 1))
            : _createCommentVNode("", true),
          (_ctx.article.version)
            ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.$t('common.version')) + ": " + _toDisplayString(_ctx.article.version), 1))
            : _createCommentVNode("", true),
          (_ctx.article.wordCount)
            ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.$t('common.words')) + ": " + _toDisplayString(_ctx.article.wordCount), 1))
            : _createCommentVNode("", true),
          (_ctx.article.readCount)
            ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.$t('common.readCount')) + ": " + _toDisplayString(_ctx.article.readCount), 1))
            : _createCommentVNode("", true)
        ])),
    (_ctx.article.hasMedia)
      ? (_openBlock(), _createBlock(_component_Media, {
          key: _ctx.id,
          resource: _ctx.resource,
          articleId: _ctx.id,
          title: _ctx.article.title
        }, null, 8, ["resource", "articleId", "title"]))
      : _createCommentVNode("", true),
    (!_ctx.iframeLink)
      ? (_openBlock(), _createElementBlock("div", {
          key: 5,
          class: "article__body mb-12",
          innerHTML: 
      _ctx.translatedBody != '' && _ctx.translatedBodyId == _ctx.article.id
        ? _ctx.translatedBody
        : _ctx.article.bodyText
    
        }, null, 8, _hoisted_14))
      : _createCommentVNode("", true),
    (_ctx.iframeLink)
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _createElementVNode("iframe", {
            style: {"width":"100%","height":"100%","position":"relative","border":"none","text-align":"left !important"},
            src: _ctx.iframeLink.value
          }, null, 8, _hoisted_16)
        ]))
      : _createCommentVNode("", true),
    (_ctx.article.classification)
      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
          _createElementVNode("span", null, " category: " + _toDisplayString(_ctx.article.classification.category), 1),
          _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
          _createElementVNode("span", null, " closestLosingDistance: " + _toDisplayString(_ctx.article.classification.closestLosingDistance), 1),
          _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
          _createElementVNode("span", null, " closestOverallDistance: " + _toDisplayString(_ctx.article.classification.closestOverallDistance), 1),
          _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
          _createElementVNode("span", null, " closestWinningDistance: " + _toDisplayString(_ctx.article.classification.closestWinningDistance), 1),
          _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
          _createElementVNode("span", null, " losingCount: " + _toDisplayString(_ctx.article.classification.losingCount), 1),
          _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
          _createElementVNode("span", null, " losingDistance: " + _toDisplayString(_ctx.article.classification.losingDistance), 1),
          _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
          _createElementVNode("span", null, " meanLosingDistance: " + _toDisplayString(_ctx.article.classification.meanLosingDistance), 1),
          _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
          _createElementVNode("span", null, " meanWinningDistance: " + _toDisplayString(_ctx.article.classification.meanWinningDistance), 1),
          _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
          _createElementVNode("span", null, " overallCount: " + _toDisplayString(_ctx.article.classification.overallCount), 1),
          _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
          _createElementVNode("span", null, " winningCount: " + _toDisplayString(_ctx.article.classification.winningCount), 1),
          _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
          _createElementVNode("span", null, " winningDistance: " + _toDisplayString(_ctx.article.classification.winningDistance), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.showTranslateButton)
      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
          _createVNode(_component_va_button, {
            size: "small",
            onClick: _ctx.fetchTranslatedBody,
            loading: _ctx.translationLoading
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('common.translateBody')), 1)
            ]),
            _: 1
          }, 8, ["onClick", "loading"]),
          _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1)),
          _cache[11] || (_cache[11] = _createElementVNode("br", null, null, -1))
        ]))
      : _createCommentVNode("", true),
    (_ctx.showOriginalBodyButton)
      ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
          _createVNode(_component_va_button, {
            size: "small",
            onClick: _ctx.fetchOriginalBody,
            loading: _ctx.translationLoading
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('common.originalBody')), 1)
            ]),
            _: 1
          }, 8, ["onClick", "loading"]),
          _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1)),
          _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1))
        ]))
      : _createCommentVNode("", true),
    (_ctx.showFeedBackButtons)
      ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
          _createVNode(_component_va_button, {
            icon: "thumb_up",
            color: "primary",
            onClick: _ctx.handleThumbsUp
          }, null, 8, ["onClick"]),
          _createVNode(_component_va_button, {
            icon: "thumb_down",
            color: "primary",
            onClick: _ctx.handleThumbsDown
          }, null, 8, ["onClick"]),
          _cache[14] || (_cache[14] = _createElementVNode("br", null, null, -1)),
          _cache[15] || (_cache[15] = _createElementVNode("br", null, null, -1))
        ]))
      : _createCommentVNode("", true),
    _cache[17] || (_cache[17] = _createElementVNode("p", null, null, -1)),
    _createElementVNode("div", _hoisted_21, [
      (_ctx.article.authors)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.article.authors, (author, index) => {
            return (_openBlock(), _createElementBlock("span", {
              class: "notranslate",
              key: index
            }, _toDisplayString(author), 1))
          }), 128))
        : _createCommentVNode("", true),
      (_ctx.article.itemId)
        ? (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(_ctx.article.itemId), 1))
        : _createCommentVNode("", true),
      (_ctx.article.copyrightNotice)
        ? (_openBlock(), _createElementBlock("span", _hoisted_23, [
            _createTextVNode(_toDisplayString(_ctx.article.copyrightNotice) + " " + _toDisplayString(_ctx.$t('common.copyrights')) + " ", 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.termsLinks, (link, index) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                _cache[16] || (_cache[16] = _createTextVNode(" | ")),
                _createElementVNode("a", {
                  href: link.value,
                  target: "_blank"
                }, _toDisplayString(link.title), 9, _hoisted_24)
              ], 64))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}