import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row justify--space-between align--center mb-6" }
const _hoisted_2 = { class: "display-5" }
const _hoisted_3 = { class: "row justify--space-between align--center mb-6" }
const _hoisted_4 = { class: "display-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Help = _resolveComponent("Help")!
  const _component_Form = _resolveComponent("Form")!
  const _component_AIForm = _resolveComponent("AIForm")!
  const _component_va_modal = _resolveComponent("va-modal")!

  return (_openBlock(), _createBlock(_component_va_modal, {
    ref: "modal",
    onBeforeOpen: _ctx.fetchQuery,
    stateful: true,
    "fixed-layout": true,
    "hide-default-actions": true
  }, {
    default: _withCtx(() => [
      (!_ctx.conceptQuery && _ctx.query)
        ? (_openBlock(), _createBlock(_component_Form, {
            key: 0,
            onDone: _ctx.handleDone,
            query: _ctx.query,
            modalForm: ""
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('search.edit-search-query')), 1),
                _createVNode(_component_Help)
              ])
            ]),
            _: 1
          }, 8, ["onDone", "query"]))
        : _createCommentVNode("", true),
      (_ctx.conceptQuery && _ctx.query)
        ? (_openBlock(), _createBlock(_component_AIForm, {
            key: 1,
            onDone: _ctx.handleDone,
            query: _ctx.query,
            modalForm: ""
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('search.edit-search-query')), 1),
                _createVNode(_component_Help)
              ])
            ]),
            _: 1
          }, 8, ["onDone", "query"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["onBeforeOpen"]))
}