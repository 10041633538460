import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "row justify--space-between align--center" }
const _hoisted_2 = {
  key: 0,
  class: "actions"
}
const _hoisted_3 = { class: "list-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_list_label = _resolveComponent("va-list-label")!
  const _component_va_list = _resolveComponent("va-list")!
  const _component_va_card = _resolveComponent("va-card")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["column flex flex--grow", { flicker: _ctx.flicker, square: _ctx.square }])
  }, [
    _createVNode(_component_va_card, { class: "wrapper" }, {
      default: _withCtx(() => [
        _createVNode(_component_va_list, { class: "list" }, {
          default: _withCtx(() => [
            _createVNode(_component_va_list_label, {
              class: "list-label",
              color: "primary"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("span", {
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleScrollTop && _ctx.handleScrollTop(...args))),
                    class: "column-title notranslate"
                  }, _toDisplayString(_ctx.title), 1),
                  (_ctx.$slots.actions)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _renderSlot(_ctx.$slots, "actions", {}, undefined, true)
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 3
            }),
            _renderSlot(_ctx.$slots, "fixed", {}, undefined, true),
            _createElementVNode("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ])
          ]),
          _: 3
        })
      ]),
      _: 3
    })
  ], 2))
}