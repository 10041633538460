import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/logo-white.svg'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_icon = _resolveComponent("va-icon")!
  const _component_va_sidebar_item_content = _resolveComponent("va-sidebar-item-content")!
  const _component_anp_popover = _resolveComponent("anp-popover")!
  const _component_va_sidebar_item = _resolveComponent("va-sidebar-item")!
  const _component_va_sidebar = _resolveComponent("va-sidebar")!

  return (_openBlock(), _createBlock(_component_va_sidebar, {
    class: "navigation-main",
    color: "primary",
    minimized: true,
    minimizedWidth: _ctx.wide ? '46rem' : '4rem'
  }, {
    default: _withCtx(() => [
      _cache[0] || (_cache[0] = _createElementVNode("img", {
        class: "logo",
        src: _imports_0,
        alt: "ANP",
        loading: "lazy"
      }, null, -1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createBlock(_component_va_sidebar_item, {
          key: item.id,
          to: item.to,
          onClick: ($event: any) => (_ctx.openItem(item.to)),
          class: _normalizeClass({ active: _ctx.isActive(item.to) })
        }, {
          default: _withCtx(() => [
            _createVNode(_component_anp_popover, {
              message: item.title,
              position: "right"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_va_sidebar_item_content, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_va_icon, {
                      name: item.icon
                    }, null, 8, ["name"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["message"])
          ]),
          _: 2
        }, 1032, ["to", "onClick", "class"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["minimizedWidth"]))
}