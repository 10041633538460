import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "row gutter-lg my-6" }
const _hoisted_2 = {
  key: 0,
  class: "calendar mr-4"
}
const _hoisted_3 = { class: "day-of-week" }
const _hoisted_4 = { class: "p-2" }
const _hoisted_5 = { class: "day-of-month" }
const _hoisted_6 = { class: "month" }
const _hoisted_7 = {
  key: 0,
  class: "time"
}
const _hoisted_8 = { class: "year" }
const _hoisted_9 = {
  key: 1,
  class: "calendar mr-4"
}
const _hoisted_10 = { class: "day-of-week" }
const _hoisted_11 = { class: "p-2" }
const _hoisted_12 = { class: "day-of-month" }
const _hoisted_13 = { class: "month" }
const _hoisted_14 = { class: "time" }
const _hoisted_15 = { class: "year" }
const _hoisted_16 = {
  key: 2,
  class: "products"
}
const _hoisted_17 = { class: "products__title mb-2" }
const _hoisted_18 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_chip = _resolveComponent("va-chip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.start)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.start.weekday), 1),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.start.day), 1),
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.start.month), 1),
            (_ctx.start.time !== '00:00')
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  (_ctx.article.isEstimatedTime)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode("±")
                      ], 64))
                    : _createCommentVNode("", true),
                  _createTextVNode(" " + _toDisplayString(_ctx.start.time) + " ", 1),
                  (_ctx.end && _ctx.sameDay && _ctx.end.time !== _ctx.start.time)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createTextVNode(" - " + _toDisplayString(_ctx.end.time), 1)
                      ], 64))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_8, "(" + _toDisplayString(_ctx.start.year) + ")", 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.end && !_ctx.sameDay)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.end.weekday), 1),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.end.day), 1),
            _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.end.month), 1),
            _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.end.time), 1),
            _createElementVNode("div", _hoisted_15, "(" + _toDisplayString(_ctx.end.year) + ")", 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.docked && _ctx.article.plannedProducts)
      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
          _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t('common.expected-production')), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.article.plannedProducts, (product, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: product }, [
              _createVNode(_component_va_chip, {
                class: "product",
                size: "small",
                outline: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(product), 1)
                ]),
                _: 2
              }, 1024),
              (index % 2 !== 0)
                ? (_openBlock(), _createElementBlock("br", _hoisted_18))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}