import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_card_title = _resolveComponent("va-card-title")!
  const _component_va_card_content = _resolveComponent("va-card-content")!
  const _component_va_card = _resolveComponent("va-card")!
  const _component_va_button = _resolveComponent("va-button")!
  const _component_va_dropdown = _resolveComponent("va-dropdown")!
  const _component_anp_popover = _resolveComponent("anp-popover")!

  return (_openBlock(), _createBlock(_component_anp_popover, {
    message: _ctx.$t('search.search-instructions')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_va_dropdown, {
        position: "right-start",
        "close-on-click-outside": false,
        "close-on-content-click": false,
        statefull: false,
        offset: [0, 20]
      }, {
        default: _withCtx(() => [
          _createVNode(_component_va_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_va_card_title, null, {
                default: _withCtx(() => [
                  _createElementVNode("h2", null, _toDisplayString(_ctx.$t('search.search-instructions')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_va_card_content, null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.helpTypes, (item) => {
                    return (_openBlock(), _createElementBlock("p", { key: item }, [
                      _createElementVNode("strong", null, _toDisplayString(_ctx.$t(`search.instructions.${item}.title`)), 1),
                      _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
                      _createElementVNode("em", null, _toDisplayString(_ctx.$t(`search.instructions.${item}.example`)), 1),
                      _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t(`search.instructions.${item}.description`)), 1)
                    ]))
                  }), 128)),
                  _createElementVNode("p", null, [
                    _createElementVNode("strong", null, _toDisplayString(_ctx.$t(`search.instructions.capitalization.title`)), 1),
                    _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t(`search.instructions.capitalization.description`)), 1)
                  ]),
                  _cache[3] || (_cache[3] = _createElementVNode("p", null, [
                    _createElementVNode("br")
                  ], -1)),
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t(`search.instructions.explanation.operators`)), 1),
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t(`search.instructions.explanation.hooks`)), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        anchor: _withCtx(() => [
          _createVNode(_component_va_button, {
            icon: "help",
            flat: ""
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["message"]))
}