import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_card_title = _resolveComponent("va-card-title")!
  const _component_va_card_content = _resolveComponent("va-card-content")!
  const _component_va_card = _resolveComponent("va-card")!

  return (_openBlock(), _createBlock(_component_va_card, { class: "placeholder" }, {
    default: _withCtx(() => [
      _createVNode(_component_va_card_title, null, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("ANP Bronnenbank")
        ])),
        _: 1
      }),
      _createVNode(_component_va_card_content, null, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("p", null, [
            _createTextVNode(" Zoek en vind nieuwe bronnen. Divers, direct te bereiken en beschikbaar voor duiding voor je journalistieke werk. "),
            _createElementVNode("br"),
            _createElementVNode("br"),
            _createTextVNode(" We streven naar een inclusieve Bronnenbank die een afspiegeling is van de samenleving. "),
            _createElementVNode("br"),
            _createTextVNode(" De Bronnenbank wil een podium bieden aan deskundigen ongeacht afkomst, leeftijd, gender, religie, regio, etc. "),
            _createElementVNode("br"),
            _createElementVNode("br"),
            _createTextVNode(" Suggesties? "),
            _createElementVNode("br"),
            _createTextVNode(" Mail naar "),
            _createElementVNode("a", { href: "mailto:bronnenbank@anp.nl" }, "bronnenbank@anp.nl")
          ], -1)
        ])),
        _: 1
      })
    ]),
    _: 1
  }))
}