import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "base" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Main = _resolveComponent("Main")!
  const _component_Sub = _resolveComponent("Sub")!
  const _component_Top = _resolveComponent("Top")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Main, {
      items: _ctx.mainItems,
      wide: (!_ctx.hasSub || !_ctx.open) && _ctx.docked,
      open: _ctx.open,
      onOpenMenuItem: _ctx.toggleOpen
    }, null, 8, ["items", "wide", "open", "onOpenMenuItem"]),
    (_ctx.hasSub && _ctx.open)
      ? (_openBlock(), _createBlock(_component_Sub, {
          key: 0,
          controls: _ctx.current?.controls,
          items: _ctx.current?.children,
          wide: _ctx.docked
        }, null, 8, ["controls", "items", "wide"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Top, {
        open: _ctx.open,
        showToggle: _ctx.hasSub && !_ctx.docked,
        toggleOpen: _ctx.toggleOpen
      }, null, 8, ["open", "showToggle", "toggleOpen"]),
      _createElementVNode("main", _hoisted_3, [
        _createVNode(_component_router_view)
      ])
    ])
  ]))
}