import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "versions" }
const _hoisted_2 = { class: "display-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_chip = _resolveComponent("va-chip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('common.versions')), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.otherVersions, (version, index) => {
      return (_openBlock(), _createBlock(_component_va_chip, {
        key: index,
        outline: "",
        size: "small",
        to: {
        name: _ctx.routeName,
        params: { id: _ctx.workspaceId, resourceId: _ctx.resourceId, articleId: version },
      }
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('common.version')) + " " + _toDisplayString(index), 1)
        ]),
        _: 2
      }, 1032, ["to"]))
    }), 128))
  ]))
}