import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, withKeys as _withKeys, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "search-form row gutter--sm" }
const _hoisted_2 = { class: "flex flex--grow" }
const _hoisted_3 = { class: "mb-6" }
const _hoisted_4 = { class: "mb-6" }
const _hoisted_5 = {
  key: 1,
  class: "mb-6"
}
const _hoisted_6 = {
  key: 2,
  class: "mb-6"
}
const _hoisted_7 = { class: "row justify--space-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_anp_message = _resolveComponent("anp-message")!
  const _component_anp_label = _resolveComponent("anp-label")!
  const _component_va_radio = _resolveComponent("va-radio")!
  const _component_va_input = _resolveComponent("va-input")!
  const _component_va_select = _resolveComponent("va-select")!
  const _component_va_date_input = _resolveComponent("va-date-input")!
  const _component_va_collapse = _resolveComponent("va-collapse")!
  const _component_Sources = _resolveComponent("Sources")!
  const _component_Calendars = _resolveComponent("Calendars")!
  const _component_va_accordion = _resolveComponent("va-accordion")!
  const _component_SourceBeta = _resolveComponent("SourceBeta")!
  const _component_va_button = _resolveComponent("va-button")!
  const _component_SaveQuery = _resolveComponent("SaveQuery")!
  const _component_anp_form = _resolveComponent("anp-form")!
  const _component_Results = _resolveComponent("Results")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass({ inset: _ctx.modalForm })
      }, [
        _createVNode(_component_anp_form, {
          ref: "form",
          onSubmit: _ctx.handleSubmit
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "header", {}, undefined, true),
            _createVNode(_component_anp_message, { message: _ctx.message }, null, 8, ["message"]),
            _createVNode(_component_anp_label, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('common.type')), 1)
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_3, [
              (_ctx.newsEnabled)
                ? (_openBlock(), _createBlock(_component_va_radio, {
                    key: 0,
                    modelValue: _ctx.type,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.type) = $event)),
                    option: _ctx.SECTION_TYPES.NEWS,
                    label: _ctx.$t('common.news')
                  }, null, 8, ["modelValue", "option", "label"]))
                : _createCommentVNode("", true),
              (_ctx.calendarEnabled)
                ? (_openBlock(), _createBlock(_component_va_radio, {
                    key: 1,
                    modelValue: _ctx.type,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.type) = $event)),
                    option: _ctx.SECTION_TYPES.CALENDAR,
                    label: _ctx.$t('common.calendar')
                  }, null, 8, ["modelValue", "option", "label"]))
                : _createCommentVNode("", true),
              (_ctx.sourceEnabled)
                ? (_openBlock(), _createBlock(_component_va_radio, {
                    key: 2,
                    modelValue: _ctx.type,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.type) = $event)),
                    option: _ctx.SECTION_TYPES.SOURCE,
                    label: _ctx.$t('source.sources')
                  }, null, 8, ["modelValue", "option", "label"]))
                : _createCommentVNode("", true)
            ]),
            (_ctx.type === _ctx.SECTION_TYPES.NEWS)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_anp_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('common.sort-by')), 1)
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_va_radio, {
                      modelValue: _ctx.sortBy,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.sortBy) = $event)),
                      option: _ctx.SORT_TYPES.DATE,
                      label: _ctx.$t('common.date')
                    }, null, 8, ["modelValue", "option", "label"]),
                    _createVNode(_component_va_radio, {
                      modelValue: _ctx.sortBy,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.sortBy) = $event)),
                      option: _ctx.SORT_TYPES.RELEVANCE,
                      label: _ctx.$t('common.relevance')
                    }, null, 8, ["modelValue", "option", "label"])
                  ])
                ], 64))
              : _createCommentVNode("", true),
            _withDirectives(_createVNode(_component_anp_label, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('common.display')), 1)
              ]),
              _: 1
            }, 512), [
              [_vShow, _ctx.type !== _ctx.SECTION_TYPES.SOURCE]
            ]),
            (_ctx.type !== _ctx.SECTION_TYPES.SOURCE)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_va_radio, {
                    modelValue: _ctx.displayType,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.displayType) = $event)),
                    option: _ctx.DISPLAY_TYPES.COMPACT,
                    label: _ctx.$t('common.compact')
                  }, null, 8, ["modelValue", "option", "label"]),
                  _createVNode(_component_va_radio, {
                    modelValue: _ctx.displayType,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.displayType) = $event)),
                    option: _ctx.DISPLAY_TYPES.SNIPPET,
                    label: _ctx.$t('common.snippet')
                  }, null, 8, ["modelValue", "option", "label"]),
                  _createVNode(_component_va_radio, {
                    modelValue: _ctx.displayType,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.displayType) = $event)),
                    option: _ctx.DISPLAY_TYPES.EXTENDED,
                    label: _ctx.$t('common.extended')
                  }, null, 8, ["modelValue", "option", "label"])
                ]))
              : (_ctx.type === _ctx.SECTION_TYPES.SOURCE)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _withDirectives(_createVNode(_component_va_radio, {
                      modelValue: _ctx.displayType,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.displayType) = $event)),
                      option: _ctx.DISPLAY_TYPES.COMPACT,
                      label: _ctx.$t('common.compact')
                    }, null, 8, ["modelValue", "option", "label"]), [
                      [_vShow, false]
                    ])
                  ]))
                : _createCommentVNode("", true),
            _createVNode(_component_anp_label, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('search.search-term')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_va_input, {
              class: "mb-8",
              modelValue: _ctx.queryText,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.queryText) = $event)),
              type: "textarea",
              rows: "4",
              onKeydown: _withKeys(_ctx.handleKey, ["enter"])
            }, null, 8, ["modelValue", "onKeydown"]),
            (
              _ctx.type === _ctx.SECTION_TYPES.NEWS || _ctx.type === _ctx.SECTION_TYPES.CALENDAR
            )
              ? (_openBlock(), _createBlock(_component_va_accordion, {
                  key: 3,
                  modelValue: _ctx.accordion,
                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.accordion) = $event)),
                  class: "mb-8",
                  multiply: true
                }, {
                  default: _withCtx(() => [
                    (_ctx.type === _ctx.SECTION_TYPES.NEWS)
                      ? (_openBlock(), _createBlock(_component_va_collapse, {
                          key: 0,
                          header: _ctx.$t('search.extensive-search')
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", null, [
                              _createVNode(_component_anp_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('common.title')), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_va_input, {
                                modelValue: _ctx.queryTitle,
                                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.queryTitle) = $event))
                              }, null, 8, ["modelValue"]),
                              _createVNode(_component_anp_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('common.author')), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_va_input, {
                                modelValue: _ctx.queryAuthor,
                                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.queryAuthor) = $event))
                              }, null, 8, ["modelValue"]),
                              (_ctx.query == null)
                                ? (_openBlock(), _createBlock(_component_anp_label, { key: 0 }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('common.period')), 1)
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true),
                              (_ctx.query == null)
                                ? (_openBlock(), _createBlock(_component_va_select, {
                                    key: 1,
                                    modelValue: _ctx.period,
                                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.period) = $event)),
                                    options: _ctx.periodOptions
                                  }, null, 8, ["modelValue", "options"]))
                                : _createCommentVNode("", true),
                              (_ctx.query == null && _ctx.period.value === '-1')
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                    _createVNode(_component_anp_label, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t('search.period-from-to')), 1)
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_va_date_input, {
                                      modelValue: _ctx.range,
                                      "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.range) = $event)),
                                      "format-date": (date) => _ctx.formatDate(date),
                                      mode: "range",
                                      "range-delimiter": " / "
                                    }, null, 8, ["modelValue", "format-date"])
                                  ], 64))
                                : _createCommentVNode("", true)
                            ])
                          ]),
                          _: 1
                        }, 8, ["header"]))
                      : _createCommentVNode("", true),
                    (
                _ctx.type === _ctx.SECTION_TYPES.NEWS || _ctx.type === _ctx.SECTION_TYPES.CALENDAR
              )
                      ? (_openBlock(), _createBlock(_component_va_collapse, {
                          key: 1,
                          header: _ctx.$t('common.sources')
                        }, {
                          default: _withCtx(() => [
                            (_ctx.type === _ctx.SECTION_TYPES.NEWS)
                              ? (_openBlock(), _createBlock(_component_Sources, {
                                  key: 0,
                                  ref: "sourcesEl",
                                  init: _ctx.sources
                                }, null, 8, ["init"]))
                              : (_openBlock(), _createBlock(_component_Calendars, {
                                  key: 1,
                                  ref: "calendarsEl",
                                  init: _ctx.calendars
                                }, null, 8, ["init"]))
                          ]),
                          _: 1
                        }, 8, ["header"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["modelValue"]))
              : _withDirectives((_openBlock(), _createBlock(_component_va_accordion, {
                  key: 4,
                  modelValue: _ctx.accordion,
                  "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.accordion) = $event)),
                  class: "mb-8",
                  multiply: true
                }, {
                  default: _withCtx(() => [
                    _withDirectives(_createVNode(_component_va_collapse, {
                      header: _ctx.$t('common.sources')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_SourceBeta, {
                          ref: "sourcesEl",
                          init: _ctx.source
                        }, null, 8, ["init"])
                      ]),
                      _: 1
                    }, 8, ["header"]), [
                      [_vShow, false]
                    ])
                  ]),
                  _: 1
                }, 8, ["modelValue"])), [
                  [_vShow, false]
                ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_va_button, {
                type: "submit",
                block: "",
                loading: _ctx.loading
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.search')), 1)
                ]),
                _: 1
              }, 8, ["loading"]),
              (
                ((_ctx.features?.managePrivateWorkspaces &&
                  _ctx.features?.managePrivateQueries) ||
                  (_ctx.features?.manageSharedWorkspaces &&
                    _ctx.features?.manageSharedQueries)) &&
                _ctx.items.length
              )
                ? (_openBlock(), _createBlock(_component_va_button, {
                    key: 0,
                    class: "mt-2",
                    block: "",
                    onClick: _ctx.showSave
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.query
                  ? _ctx.$t('search.overwrite-search-query')
                  : _ctx.$t('search.save-search-query')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true),
              _createVNode(_component_SaveQuery, {
                ref: "saveModal",
                onDone: _ctx.handleDone,
                query: _ctx.query,
                type: _ctx.type,
                searchData: {
                queryText: _ctx.queryText,
                queryTitle: _ctx.queryTitle,
                queryAuthor: _ctx.queryAuthor,
                period: _ctx.period,
                fromDate: _ctx.fromDate,
                toDate: _ctx.toDate,
                sourceId: _ctx.sourceId,
                sourcesIds: _ctx.sourcesIds,
                calendarsIds: _ctx.calendarsIds,
              }
              }, null, 8, ["onDone", "query", "type", "searchData"])
            ])
          ]),
          _: 3
        }, 8, ["onSubmit"])
      ], 2)
    ]),
    _createVNode(_component_Results, {
      items: _ctx.items,
      count: _ctx.count,
      showDescription: _ctx.showDescription,
      square: _ctx.modalForm,
      "loading-more": _ctx.loadingMore || _ctx.loading,
      onLoadmore: _ctx.loadMore
    }, null, 8, ["items", "count", "showDescription", "square", "loading-more", "onLoadmore"])
  ]))
}