import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "event-time row align--center"
}
const _hoisted_2 = {
  key: 1,
  class: "row gutter--xs mb-0"
}
const _hoisted_3 = { class: "flex flex--grow" }
const _hoisted_4 = { class: "flex image__wrapper" }
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = { key: 2 }
const _hoisted_7 = {
  key: 3,
  class: "intro"
}
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = {
  key: 4,
  class: "row justify--space-between align--center"
}
const _hoisted_10 = { class: "data" }
const _hoisted_11 = {
  key: 0,
  class: "data__item pub-time"
}
const _hoisted_12 = { class: "data__title" }
const _hoisted_13 = { class: "data__type" }
const _hoisted_14 = {
  key: 1,
  class: "data__item pub-date"
}
const _hoisted_15 = { class: "data__title" }
const _hoisted_16 = { class: "data__type" }
const _hoisted_17 = {
  key: 2,
  class: "data__item words"
}
const _hoisted_18 = { class: "data__title" }
const _hoisted_19 = { class: "data__type" }
const _hoisted_20 = {
  key: 3,
  class: "data__item version"
}
const _hoisted_21 = { class: "data__title" }
const _hoisted_22 = { class: "data__type" }
const _hoisted_23 = {
  key: 4,
  class: "data__item urgency"
}
const _hoisted_24 = { class: "data__title" }
const _hoisted_25 = { class: "data__type" }
const _hoisted_26 = {
  key: 5,
  class: "data__item source"
}
const _hoisted_27 = { class: "data__title" }
const _hoisted_28 = { class: "data__type" }
const _hoisted_29 = {
  key: 6,
  class: "data__item score"
}
const _hoisted_30 = { class: "data__title" }
const _hoisted_31 = { class: "data__type" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_icon = _resolveComponent("va-icon")!
  const _component_va_chip = _resolveComponent("va-chip")!
  const _component_va_button = _resolveComponent("va-button")!
  const _component_anp_popover = _resolveComponent("anp-popover")!
  const _component_va_list_item_section = _resolveComponent("va-list-item-section")!
  const _component_va_list_item = _resolveComponent("va-list-item")!

  return (_openBlock(), _createBlock(_component_va_list_item, {
    onClick: _ctx.handleClick,
    class: _normalizeClass({
      'priority-1': _ctx.item.urgency === 1,
      'priority-2': _ctx.item.urgency === 2,
      read: _ctx.isRead,
      active: _ctx.active,
    })
  }, {
    default: _withCtx(() => [
      _createVNode(_component_va_list_item_section, null, {
        default: _withCtx(() => [
          (_ctx.eventTime)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_va_icon, {
                  name: "schedule",
                  size: "small",
                  class: "mr-1"
                }),
                (_ctx.item.isEstimatedTime)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode("±")
                    ], 64))
                  : _createCommentVNode("", true),
                _createTextVNode(" " + _toDisplayString(_ctx.eventTime) + " ", 1),
                (_ctx.item.plannedProducts)
                  ? (_openBlock(), _createBlock(_component_va_chip, {
                      key: 1,
                      class: "ml-auto p-0",
                      size: "small",
                      color: "gray",
                      outline: "",
                      round: ""
                    }, {
                      default: _withCtx(() => _cache[0] || (_cache[0] = [
                        _createTextVNode(" T ")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (_ctx.item.thumbUrl && _ctx.showImage)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("h3", null, _toDisplayString(_ctx.item.title), 1)
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("img", {
                    src: _ctx.item.thumbUrl,
                    alt: _ctx.item.title,
                    class: "image",
                    width: "150",
                    height: "100",
                    loading: "lazy"
                  }, null, 8, _hoisted_5)
                ])
              ]))
            : (_openBlock(), _createElementBlock("h3", _hoisted_6, _toDisplayString(_ctx.item.title), 1)),
          (_ctx.item.introText && _ctx.showDescription)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("p", {
                  innerHTML: _ctx.item.introText
                }, null, 8, _hoisted_8)
              ]))
            : _createCommentVNode("", true),
          (
          (_ctx.formattedTime ||
            _ctx.formattedDate ||
            _ctx.item.wordCount ||
            _ctx.item.version ||
            _ctx.item.urgency) &&
          _ctx.showMeta &&
          _ctx.item.kind !== 'PERSON'
        )
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  (!_ctx.eventTime && _ctx.formattedTime)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.formattedTime), 1),
                        _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('common.time')), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (!_ctx.eventTime && _ctx.formattedDate)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_14, [
                        _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.formattedDate), 1),
                        _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.formattedYear), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.item.wordCount)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_17, [
                        _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.item.wordCount), 1),
                        _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t('common.words')), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.item.version && !_ctx.showSource)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_20, [
                        _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.item.version), 1),
                        _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t('common.version')), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.item.urgency && !_ctx.showSource)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_23, [
                        _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.item.urgency), 1),
                        _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.$t('common.priority')), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.item.sourceTitle && _ctx.showSource)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_26, [
                        _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.item.sourceTitle), 1),
                        _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.$t('common.source')), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.item.score)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_29, [
                        _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.item.score), 1),
                        _createElementVNode("div", _hoisted_31, _toDisplayString(_ctx.$t('common.score')), 1)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _createVNode(_component_anp_popover, {
                  class: _normalizeClass(["clip", { active: _ctx.isClipped }]),
                  message: 
            _ctx.isClipped
              ? _ctx.$t('common.clippings-remove')
              : _ctx.$t('common.clippings-add')
          
                }, {
                  default: _withCtx(() => [
                    (_ctx.features?.useClippings)
                      ? (_openBlock(), _createBlock(_component_va_button, {
                          key: 0,
                          icon: "content_cut",
                          size: "small",
                          outline: !_ctx.clipLoading && _ctx.isClipped,
                          flat: _ctx.clipLoading || !_ctx.isClipped,
                          onClick: _withModifiers(_ctx.handleClip, ["stop"]),
                          loading: _ctx.clipLoading
                        }, null, 8, ["outline", "flat", "onClick", "loading"]))
                      : _createCommentVNode("", true),
                    (_ctx.showFeedBackButtons)
                      ? (_openBlock(), _createBlock(_component_va_button, {
                          key: 1,
                          class: "thumbsupdown",
                          icon: "thumb_down",
                          size: "small",
                          loading: _ctx.feedbackLoading,
                          onClick: _withModifiers(_ctx.handleThumbsDown, ["stop"])
                        }, null, 8, ["loading", "onClick"]))
                      : _createCommentVNode("", true),
                    (_ctx.showFeedBackButtons)
                      ? (_openBlock(), _createBlock(_component_va_button, {
                          key: 2,
                          class: "thumbsupdown",
                          icon: "thumb_up",
                          size: "small",
                          loading: _ctx.feedbackLoading,
                          onClick: _withModifiers(_ctx.handleThumbsUp, ["stop"])
                        }, null, 8, ["loading", "onClick"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["class", "message"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onClick", "class"]))
}