import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "actions row justify--space-between align--center mb-4 -mt-2 -mx-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_button = _resolveComponent("va-button")!
  const _component_anp_popover = _resolveComponent("anp-popover")!
  const _component_Mail = _resolveComponent("Mail")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        (!_ctx.showNavigation)
          ? (_openBlock(), _createBlock(_component_anp_popover, {
              key: 0,
              message: _ctx.$t('common.back')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_va_button, {
                  icon: "arrow_back",
                  onClick: _ctx.handleBack,
                  flat: ""
                }, null, 8, ["onClick"])
              ]),
              _: 1
            }, 8, ["message"]))
          : _createCommentVNode("", true),
        (_ctx.showNavigation)
          ? (_openBlock(), _createBlock(_component_anp_popover, {
              key: 1,
              message: _ctx.$t('common.previous')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_va_button, {
                  disabled: !_ctx.hasPrevious,
                  icon: "arrow_upward",
                  flat: "",
                  onClick: _ctx.previousArticle
                }, null, 8, ["disabled", "onClick"])
              ]),
              _: 1
            }, 8, ["message"]))
          : _createCommentVNode("", true),
        (_ctx.showNavigation)
          ? (_openBlock(), _createBlock(_component_anp_popover, {
              key: 2,
              message: _ctx.$t('common.next')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_va_button, {
                  disabled: !_ctx.hasNext,
                  icon: "arrow_downward",
                  flat: "",
                  onClick: _ctx.nextArticle
                }, null, 8, ["disabled", "onClick"])
              ]),
              _: 1
            }, 8, ["message"]))
          : _createCommentVNode("", true),
        _createVNode(_component_anp_popover, {
          message: _ctx.$t('common.print')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_va_button, {
              onClick: _withModifiers(_ctx.handlePrint, ["prevent"]),
              icon: "print",
              flat: ""
            }, null, 8, ["onClick"])
          ]),
          _: 1
        }, 8, ["message"]),
        (_ctx.canSend)
          ? (_openBlock(), _createBlock(_component_anp_popover, {
              key: 3,
              message: _ctx.$t('common.mail')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_va_button, {
                  onClick: _withModifiers(_ctx.handleMail, ["prevent"]),
                  icon: "mail",
                  flat: ""
                }, null, 8, ["onClick"])
              ]),
              _: 1
            }, 8, ["message"]))
          : _createCommentVNode("", true),
        (_ctx.features?.useClippings)
          ? (_openBlock(), _createBlock(_component_anp_popover, {
              key: 4,
              message: 
          _ctx.isClipped ? _ctx.$t('common.clippings-remove') : _ctx.$t('common.clippings-add')
        
            }, {
              default: _withCtx(() => [
                _createVNode(_component_va_button, {
                  icon: "content_cut",
                  outline: !_ctx.clipLoading && _ctx.isClipped,
                  flat: _ctx.clipLoading || !_ctx.isClipped,
                  onClick: _withModifiers(_ctx.handleClip, ["prevent"]),
                  loading: _ctx.clipLoading
                }, null, 8, ["outline", "flat", "onClick", "loading"])
              ]),
              _: 1
            }, 8, ["message"]))
          : _createCommentVNode("", true),
        _createVNode(_component_anp_popover, {
          message: 
          _ctx.docked ? _ctx.$t('common.view-as-modal') : _ctx.$t('common.dock-to-sidebar')
        
        }, {
          default: _withCtx(() => [
            _createVNode(_component_va_button, {
              class: _normalizeClass({ rotate: !_ctx.docked }),
              icon: "crop_7_5",
              onClick: _ctx.handleDock,
              flat: ""
            }, null, 8, ["class", "onClick"])
          ]),
          _: 1
        }, 8, ["message"])
      ]),
      _createVNode(_component_anp_popover, {
        message: _ctx.$t('actions.close')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_va_button, {
            onClick: _withModifiers(_ctx.handleClose, ["prevent"]),
            icon: "close",
            flat: ""
          }, null, 8, ["onClick"])
        ]),
        _: 1
      }, 8, ["message"])
    ]),
    _createVNode(_component_Mail, {
      ref: "mailModal",
      article: _ctx.article
    }, null, 8, ["article"])
  ], 64))
}