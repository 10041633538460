import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row flex--nowrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_button = _resolveComponent("va-button")!
  const _component_anp_popover = _resolveComponent("anp-popover")!
  const _component_Settings = _resolveComponent("Settings")!
  const _component_va_progress_circle = _resolveComponent("va-progress-circle")!
  const _component_va_input = _resolveComponent("va-input")!
  const _component_anp_column_item = _resolveComponent("anp-column-item")!
  const _component_anp_column_skeleton = _resolveComponent("anp-column-skeleton")!
  const _component_anp_column_empty = _resolveComponent("anp-column-empty")!
  const _component_anp_column = _resolveComponent("anp-column")!

  return (_openBlock(), _createBlock(_component_anp_column, { title: _ctx.title }, {
    actions: _withCtx(() => [
      _createVNode(_component_anp_popover, {
        message: _ctx.$t('common.search')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_va_button, {
            icon: "search",
            color: "primary",
            flat: "",
            onClick: _ctx.toggleSearch
          }, null, 8, ["onClick"])
        ]),
        _: 1
      }, 8, ["message"]),
      (_ctx.resource != null && _ctx.workspace.editable)
        ? (_openBlock(), _createBlock(_component_anp_popover, {
            key: 0,
            message: _ctx.$t('common.options')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Settings, {
                onDoneEdit: _ctx.handleReload,
                resource: _ctx.resource,
                workspace: _ctx.workspace
              }, null, 8, ["onDoneEdit", "resource", "workspace"])
            ]),
            _: 1
          }, 8, ["message"]))
        : _createCommentVNode("", true)
    ]),
    fixed: _withCtx(() => [
      _createElementVNode("div", {
        style: _normalizeStyle({ display: _ctx.showSearch ? 'block' : 'none' })
      }, [
        _createVNode(_component_va_input, {
          ref: "input",
          modelValue: _ctx.search,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
          placeholder: _ctx.$t('calendar.search-in-day'),
          onKeyup: _ctx.handleKey
        }, {
          appendInner: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              (!_ctx.searchLoading)
                ? (_openBlock(), _createBlock(_component_anp_popover, {
                    key: 0,
                    message: _ctx.$t('common.search')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_va_button, {
                        icon: "arrow_forward",
                        color: "dark",
                        flat: "",
                        onClick: _ctx.handleSearch
                      }, null, 8, ["onClick"])
                    ]),
                    _: 1
                  }, 8, ["message"]))
                : (_openBlock(), _createBlock(_component_va_progress_circle, {
                    key: 1,
                    size: "small",
                    thickness: 0.2,
                    indeterminate: ""
                  })),
              _createVNode(_component_anp_popover, {
                message: _ctx.$t('actions.close')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_va_button, {
                    icon: "close",
                    color: "dark",
                    flat: "",
                    onClick: _ctx.toggleSearch
                  }, null, 8, ["onClick"])
                ]),
                _: 1
              }, 8, ["message"])
            ])
          ]),
          _: 1
        }, 8, ["modelValue", "placeholder", "onKeyup"])
      ], 4)
    ]),
    default: _withCtx(() => [
      (_ctx.renderItems?.length)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.renderItems, (renderItem) => {
            return (_openBlock(), _createBlock(_component_anp_column_item, {
              key: renderItem.id,
              item: renderItem,
              resourceId: _ctx.resourceId,
              articleRouteName: _ctx.CALENDAR_ARTICLE,
              articleIds: _ctx.renderItemIds,
              showMeta: ""
            }, null, 8, ["item", "resourceId", "articleRouteName", "articleIds"]))
          }), 128))
        : (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_anp_column_skeleton, {
              key: 1,
              date: ""
            }))
          : (_ctx.isEmpty)
            ? (_openBlock(), _createBlock(_component_anp_column_empty, {
                key: 2,
                type: "calendar"
              }))
            : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title"]))
}