import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "blank" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "main" }
const _hoisted_4 = { class: "row justify--center" }
const _hoisted_5 = { class: "view flex md6 px-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Top = _resolveComponent("Top")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Top),
      _createElementVNode("main", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_router_view)
          ])
        ])
      ])
    ])
  ]))
}