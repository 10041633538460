import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "article" }
const _hoisted_2 = {
  key: 1,
  class: "article__main"
}
const _hoisted_3 = { class: "article__sidebar" }
const _hoisted_4 = { key: 6 }
const _hoisted_5 = { key: 9 }
const _hoisted_6 = {
  key: 1,
  class: "loader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_Content = _resolveComponent("Content")!
  const _component_ContentSource = _resolveComponent("ContentSource")!
  const _component_Actions = _resolveComponent("Actions")!
  const _component_Versions = _resolveComponent("Versions")!
  const _component_Links = _resolveComponent("Links")!
  const _component_Entities = _resolveComponent("Entities")!
  const _component_QuotedSources = _resolveComponent("QuotedSources")!
  const _component_MediaTopics = _resolveComponent("MediaTopics")!
  const _component_Related = _resolveComponent("Related")!
  const _component_va_chip = _resolveComponent("va-chip")!
  const _component_MediaTopicsWeaviate = _resolveComponent("MediaTopicsWeaviate")!
  const _component_va_progress_circle = _resolveComponent("va-progress-circle")!
  const _component_va_card = _resolveComponent("va-card")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["modal", { docked: _ctx.docked }]),
    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleClose && _ctx.handleClose(...args)))
  }, [
    _createVNode(_component_va_card, {
      onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (_ctx.data)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_Column, {
                  resource: _ctx.resource,
                  hidden: _ctx.docked,
                  articleId: _ctx.articleId
                }, null, 8, ["resource", "hidden", "articleId"]),
                (_ctx.data.sourceTitle !== 'ANP Bronnenbank')
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(["article__main", _ctx.iframe ? 'iframed-article' : ''])
                    }, [
                      _createVNode(_component_Content, {
                        article: _ctx.data,
                        id: _ctx.articleId,
                        resource: _ctx.resource,
                        docked: _ctx.docked,
                        "overview-route-name": _ctx.overviewRouteName,
                        onContainsIframe: _ctx.handleContainsIframe,
                        onContainsNoIframe: _ctx.handleContainsNoIframe
                      }, null, 8, ["article", "id", "resource", "docked", "overview-route-name", "onContainsIframe", "onContainsNoIframe"])
                    ], 2))
                  : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createVNode(_component_ContentSource, {
                        article: _ctx.data,
                        id: _ctx.articleId,
                        resource: _ctx.resource,
                        docked: _ctx.docked
                      }, null, 8, ["article", "id", "resource", "docked"])
                    ])),
                _createElementVNode("aside", _hoisted_3, [
                  _createVNode(_component_Actions, {
                    article: _ctx.data,
                    resource: _ctx.resource,
                    docked: _ctx.docked,
                    onDock: _ctx.toggleDocked,
                    onClose: _ctx.handleClose
                  }, null, 8, ["article", "resource", "docked", "onDock", "onClose"]),
                  (_ctx.data.otherVersions)
                    ? (_openBlock(), _createBlock(_component_Versions, {
                        key: 0,
                        article: _ctx.data
                      }, null, 8, ["article"]))
                    : _createCommentVNode("", true),
                  (_ctx.data.links?.length)
                    ? (_openBlock(), _createBlock(_component_Links, {
                        key: 1,
                        article: _ctx.data
                      }, null, 8, ["article"]))
                    : _createCommentVNode("", true),
                  (_ctx.data.entities?.length)
                    ? (_openBlock(), _createBlock(_component_Entities, {
                        key: 2,
                        article: _ctx.data
                      }, null, 8, ["article"]))
                    : _createCommentVNode("", true),
                  (_ctx.data.quotedSources?.length)
                    ? (_openBlock(), _createBlock(_component_QuotedSources, {
                        key: 3,
                        article: _ctx.data
                      }, null, 8, ["article"]))
                    : _createCommentVNode("", true),
                  (_ctx.data.mediaCodes?.length && _ctx.showMediaTopics)
                    ? (_openBlock(), _createBlock(_component_MediaTopics, {
                        key: 4,
                        article: _ctx.data
                      }, null, 8, ["article"]))
                    : _createCommentVNode("", true),
                  (_ctx.data.suggestedSources)
                    ? (_openBlock(), _createBlock(_component_Related, {
                        key: 5,
                        title: _ctx.$t('common.suggested-sources'),
                        items: _ctx.data.suggestedSources
                      }, null, 8, ["title", "items"]))
                    : _createCommentVNode("", true),
                  (
                _ctx.data.suggestedSources &&
                !_ctx.compareSuggestedSources &&
                _ctx.features?.compareExpertSuggestions
              )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createVNode(_component_va_chip, {
                          size: "small",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.compareSuggestedSources = true))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('common.comparesuggestions')), 1)
                          ]),
                          _: 1
                        }),
                        _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
                        _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1))
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.compareSuggestedSources)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 7 }, _renderList(_ctx.features?.embeddingModels, (item) => {
                        return (_openBlock(), _createBlock(_component_Related, {
                          key: item,
                          title: _ctx.$t('common.suggested-sources') + ' ' + item,
                          "article-id": _ctx.data.id,
                          vectorizer: item,
                          type: "expert"
                        }, null, 8, ["title", "article-id", "vectorizer"]))
                      }), 128))
                    : _createCommentVNode("", true),
                  (
                !_ctx.showWeaviateRelated &&
                _ctx.features?.aiSearch &&
                _ctx.data.relationships.includes(_ctx.ARTICLE_RELATIONSHIP.OTHERAGENCIES)
              )
                    ? (_openBlock(), _createBlock(_component_Related, {
                        key: 8,
                        title: _ctx.$t('common.relatedweaviate'),
                        type: _ctx.ARTICLE_RELATIONSHIP.OTHERAGENCIES,
                        articleId: _ctx.articleId,
                        vectorizer: "small3",
                        "show-time": true,
                        resource: _ctx.resource
                      }, null, 8, ["title", "type", "articleId", "resource"]))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.features?.embeddingModels, (item) => {
                    return (_openBlock(), _createElementBlock(_Fragment, { key: item }, [
                      (_ctx.features?.showMediaTopics && item == 'ada002')
                        ? (_openBlock(), _createBlock(_component_MediaTopicsWeaviate, {
                            key: 0,
                            article: _ctx.data,
                            vectorizer: item,
                            "show-score": _ctx.compareMediaTopics
                          }, null, 8, ["article", "vectorizer", "show-score"]))
                        : (_ctx.compareMediaTopics)
                          ? (_openBlock(), _createBlock(_component_MediaTopicsWeaviate, {
                              key: 1,
                              article: _ctx.data,
                              vectorizer: item,
                              "show-score": _ctx.compareMediaTopics
                            }, null, 8, ["article", "vectorizer", "show-score"]))
                          : _createCommentVNode("", true)
                    ], 64))
                  }), 128)),
                  (!_ctx.compareMediaTopics && _ctx.features?.compareMediaTopics)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createVNode(_component_va_chip, {
                          size: "small",
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.compareMediaTopics = true))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('common.comparesuggestions')), 1)
                          ]),
                          _: 1
                        })
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.showWeaviateRelated && _ctx.features?.weaviate)
                    ? (_openBlock(), _createBlock(_component_Related, {
                        key: 10,
                        title: _ctx.$t('common.relatedweaviate'),
                        type: _ctx.ARTICLE_RELATIONSHIP.WEAVIATE,
                        articleId: _ctx.articleId,
                        resource: _ctx.resource
                      }, null, 8, ["title", "type", "articleId", "resource"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_Related, {
                    title: _ctx.$t('common.related'),
                    type: _ctx.ARTICLE_RELATIONSHIP.ALIKE,
                    articleId: _ctx.articleId,
                    resource: _ctx.resource
                  }, null, 8, ["title", "type", "articleId", "resource"]),
                  (_ctx.data.relationships.includes(_ctx.ARTICLE_RELATIONSHIP.TOPIC))
                    ? (_openBlock(), _createBlock(_component_Related, {
                        key: 11,
                        title: _ctx.$t('common.topic'),
                        type: _ctx.ARTICLE_RELATIONSHIP.TOPIC,
                        articleId: _ctx.articleId,
                        resource: _ctx.resource
                      }, null, 8, ["title", "type", "articleId", "resource"]))
                    : _createCommentVNode("", true)
                ])
              ], 64))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_va_progress_circle, {
                  size: "large",
                  thickness: 0.2,
                  indeterminate: ""
                })
              ]))
        ])
      ]),
      _: 1
    })
  ], 2))
}