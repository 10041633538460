import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/google.svg'
import _imports_1 from '@/assets/images/microsoft.svg'


const _hoisted_1 = {
  class: "mt-6",
  style: {"line-height":"1.4"}
}
const _hoisted_2 = {
  href: "/zelfregistratie/",
  style: {"color":"#0039d8"},
  class: "register"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_card_title = _resolveComponent("va-card-title")!
  const _component_anp_message = _resolveComponent("anp-message")!
  const _component_anp_label = _resolveComponent("anp-label")!
  const _component_va_input = _resolveComponent("va-input")!
  const _component_anp_link = _resolveComponent("anp-link")!
  const _component_va_checkbox = _resolveComponent("va-checkbox")!
  const _component_va_button = _resolveComponent("va-button")!
  const _component_anp_form = _resolveComponent("anp-form")!
  const _component_va_card_content = _resolveComponent("va-card-content")!
  const _component_va_card = _resolveComponent("va-card")!

  return (_openBlock(), _createBlock(_component_va_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_va_card_title, null, {
        default: _withCtx(() => [
          _createElementVNode("h1", null, _toDisplayString(_ctx.$t('auth.login-title')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_va_card_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_anp_form, {
            ref: "form",
            onSubmit: _ctx.handleSubmit
          }, {
            default: _withCtx(() => [
              _createVNode(_component_anp_message, { message: _ctx.message }, null, 8, ["message"]),
              _createVNode(_component_anp_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('user.email')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_va_input, {
                modelValue: _ctx.email,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                rules: [_ctx.required, _ctx.validEmail]
              }, null, 8, ["modelValue", "rules"]),
              _createVNode(_component_anp_label, null, {
                right: _withCtx(() => [
                  _createVNode(_component_anp_link, {
                    to: { name: _ctx.AUTH_FORGOT_PASSWORD }
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('auth.forgot-password')), 1)
                    ]),
                    _: 1
                  }, 8, ["to"])
                ]),
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('user.password')) + " ", 1)
                ]),
                _: 1
              }),
              _createVNode(_component_va_input, {
                modelValue: _ctx.password,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
                type: "password",
                rules: [_ctx.required]
              }, null, 8, ["modelValue", "rules"]),
              _createVNode(_component_va_checkbox, {
                modelValue: _ctx.remember,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.remember) = $event)),
                label: _ctx.$t('auth.remember-me')
              }, null, 8, ["modelValue", "label"]),
              _createVNode(_component_va_button, {
                block: "",
                class: "mt-4",
                type: "submit",
                loading: _ctx.loading
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('auth.login')), 1)
                ]),
                _: 1
              }, 8, ["loading"]),
              _createVNode(_component_va_button, {
                block: "",
                class: "mt-4",
                onClick: _ctx.handleGoogle,
                outline: ""
              }, {
                default: _withCtx(() => [
                  _cache[3] || (_cache[3] = _createElementVNode("img", {
                    src: _imports_0,
                    class: "google-icon",
                    alt: "Google",
                    loading: "lazy"
                  }, null, -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('auth.login-with-google')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_va_button, {
                block: "",
                class: "mt-4",
                onClick: _ctx.handleMicrosoft,
                outline: ""
              }, {
                default: _withCtx(() => [
                  _cache[4] || (_cache[4] = _createElementVNode("img", {
                    src: _imports_1,
                    class: "google-icon",
                    alt: "Google",
                    loading: "lazy"
                  }, null, -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('auth.login-with-microsoft')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          }, 8, ["onSubmit"]),
          _createElementVNode("p", _hoisted_1, [
            _createTextVNode(_toDisplayString(_ctx.$t('auth.no-account')) + " ", 1),
            _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("a", _hoisted_2, _toDisplayString(_ctx.$t('auth.register')), 1)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}