import { openBlock as _openBlock, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@/assets/images/logo-blue.svg'


const _hoisted_1 = { class: "auth" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "background-image" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "inner" }
const _hoisted_6 = { class: "footer" }
const _hoisted_7 = { class: "footer__legal" }
const _hoisted_8 = {
  href: "https://www.anp.nl/disclaimer-en-privacy-statement-anp",
  target: "_blank"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("main", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_Transition, {
          name: "fade",
          mode: "in-out"
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createElementBlock("img", {
              src: _ctx.backgrounds[_ctx.randomInt],
              key: _ctx.randomInt,
              alt: "ANP"
            }, null, 8, _hoisted_4))
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_router_view)
      ])
    ]),
    _createElementVNode("footer", _hoisted_6, [
      _cache[0] || (_cache[0] = _createElementVNode("img", {
        src: _imports_0,
        class: "footer__logo",
        loading: "lazy",
        alt: "ANP"
      }, null, -1)),
      _createElementVNode("div", _hoisted_7, [
        _createTextVNode(_toDisplayString(_ctx.$t('common.copyright', { year: _ctx.year })) + " ", 1),
        _createElementVNode("a", _hoisted_8, _toDisplayString(_ctx.$t('common.privacy-policy')), 1)
      ])
    ])
  ]))
}