import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "article__title mb-4" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "article__avatar" }
const _hoisted_5 = { class: "article__card" }
const _hoisted_6 = { class: "article__title mb-6" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "article__meta" }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { key: 2 }
const _hoisted_12 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_va_divider = _resolveComponent("va-divider")!
  const _component_SourceMedia = _resolveComponent("SourceMedia")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        class: "article__title-image",
        src: `${_ctx.baseUri}sources/${_ctx.resourceId}/logo`,
        alt: _ctx.$t('common.origin'),
        loading: "lazy"
      }, null, 8, _hoisted_2)
    ]),
    (_ctx.article.editorialNote)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          innerHTML: _ctx.article.editorialNote,
          class: "article__editor-note my-4"
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true),
    (_ctx.article.kind === _ctx.ARTICLE_TYPES.EVENT)
      ? (_openBlock(), _createBlock(_component_Calendar, {
          key: 1,
          article: _ctx.article,
          docked: _ctx.docked
        }, null, 8, ["article", "docked"]))
      : _createCommentVNode("", true),
    _createVNode(_component_va_divider),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (_ctx.article.hasMedia)
          ? (_openBlock(), _createBlock(_component_SourceMedia, {
              key: _ctx.id,
              resource: _ctx.resource,
              articleId: _ctx.id,
              title: _ctx.article.title
            }, null, 8, ["resource", "articleId", "title"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("h1", {
        innerHTML: _ctx.article.title
      }, null, 8, _hoisted_7)
    ]),
    _createElementVNode("div", {
      class: "article__body mb-12",
      innerHTML: _ctx.article.bodyText
    }, null, 8, _hoisted_8),
    _createElementVNode("div", _hoisted_9, [
      (_ctx.article.authors)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.article.authors, (author, index) => {
            return (_openBlock(), _createElementBlock("span", { key: index }, _toDisplayString(author), 1))
          }), 128))
        : _createCommentVNode("", true),
      (_ctx.article.itemId)
        ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.article.itemId), 1))
        : _createCommentVNode("", true),
      (_ctx.article.copyrightNotice)
        ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
            _createTextVNode(_toDisplayString(_ctx.article.copyrightNotice) + " " + _toDisplayString(_ctx.$t('common.copyrights')) + " ", 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.termsLinks, (link, index) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                _cache[0] || (_cache[0] = _createTextVNode(" | ")),
                _createElementVNode("a", {
                  href: link.value,
                  target: "_blank"
                }, _toDisplayString(link.title), 9, _hoisted_12)
              ], 64))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}