import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "popup-list"
}
const _hoisted_3 = ["href"]
const _hoisted_4 = {
  key: 1,
  class: "popup-list"
}
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "row justify--end mt-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_anp_message = _resolveComponent("anp-message")!
  const _component_va_button = _resolveComponent("va-button")!
  const _component_va_modal = _resolveComponent("va-modal")!

  return (_openBlock(), _createBlock(_component_va_modal, {
    ref: "modal",
    stateful: true,
    title: _ctx.$t('common.contact-info'),
    "hide-default-actions": true
  }, {
    default: _withCtx(() => [
      _createElementVNode("template", null, [
        _createTextVNode(_toDisplayString(_ctx.$t('search.overwrite-search-query-confirm')) + " ", 1),
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "row justify--end mt-8" }, null, -1))
      ]),
      _createVNode(_component_anp_message, { message: _ctx.message }, null, 8, ["message"]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link, index) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
          (link.title === 'phone' || link.title === 'email')
            ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
                (link.title === 'phone')
                  ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
                      _createTextVNode(_toDisplayString(_ctx.$t('common.phonenumber')) + ": ", 1),
                      _createElementVNode("a", {
                        class: "popup-link",
                        href: link.value
                      }, _toDisplayString(link.value.split(':')[1]), 9, _hoisted_3)
                    ]))
                  : (_openBlock(), _createElementBlock("li", _hoisted_4, [
                      _createTextVNode(_toDisplayString(_ctx.$t('user.email')) + ": ", 1),
                      _createElementVNode("a", {
                        href: link.value
                      }, _toDisplayString(link.value.split(':')[1]), 9, _hoisted_5)
                    ]))
              ]))
            : _createCommentVNode("", true)
        ], 64))
      }), 128)),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_va_button, {
          color: "primary",
          flat: "",
          onClick: _ctx.hide
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('actions.close')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}